import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import './App.css';
import classImage from './image.png'; 

// Positions des noms (sans coordonnées spécifiques)
const initialPositions = [
  { id: 1, name: "Safoua" },
  { id: 2, name: "Léa" },
  { id: 3, name: "Alexandre" },
  { id: 4, name: "Prescillia Gimard" },
  { id: 5, name: "Noémie" },
  { id: 6, name: "Bruno" },
  { id: 7, name: "Daniel" },
  { id: 8, name: "Axelle Douguet" },
  { id: 9, name: "Ocan" },
  { id: 10, name: "Govely" },
  { id: 11, name: "Jessica" },
  { id: 12, name: "Mélissa" },
  { id: 13, name: "Sulaiman" },
  { id: 14, name: "Berdan" },
  { id: 15, name: "Alexis" },
  { id: 16, name: "Lucas" },
  { id: 17, name: "Charonne" },
  { id: 18, name: "Sithursha Sivathevan" },
  { id: 19, name: "Nicolas" },
  { id: 20, name: "Hassan" },
  { id: 21, name: "Franck NKITA" },
  { id: 22, name: "Yadussa" },
  { id: 23, name: "Ilias" },
  { id: 24, name: "Lyna Mostefa Chaa" },
  { id: 25, name: "Dilber" },
  { id: 26, name: "Naud" },
  { id: 27, name: "Manny" },
  { id: 28, name: "Tortue Renard"}
];

function App() {
  const [positions, setPositions] = useState(null);  // positions initialisé à null
  const [resetKey, setResetKey] = useState(0);

  // Charger les positions depuis localStorage ou utiliser les positions par défaut
  useEffect(() => {
    const savedPositions = JSON.parse(localStorage.getItem('positions'));
    if (savedPositions) {
      setPositions(savedPositions);  // Utiliser les positions sauvegardées
    } else {
      setPositions(initialPositions);  // Utiliser les positions initiales
    }
  }, []);

  // Sauvegarder les positions dans le localStorage à chaque mise à jour
  useEffect(() => {
    if (positions) {  // Sauvegarder seulement si positions est défini
      localStorage.setItem('positions', JSON.stringify(positions));
    }
  }, [positions]);

  // Fonction pour réinitialiser les positions des noms
  const resetPositions = () => {
    setPositions([...initialPositions]);
    setResetKey(resetKey + 1);
    localStorage.removeItem('positions'); // Supprimer les positions sauvegardées pour revenir à l'état initial
  };

  // Mise à jour des positions pendant le drag
  const handleDrag = (e, data, id) => {
    const newPositions = positions.map(pos =>
      pos.id === id ? { ...pos, x: data.x, y: data.y } : pos
    );
    setPositions(newPositions);
  };

  if (!positions) {
    return <div>Loading...</div>;  // Afficher un écran de chargement pendant le chargement des positions
  }

  return (
    <div className="App">
      <h1>Classe de CM1/CM2 à René Coty Gonesse - 2011/2012</h1>
      <div className="class-photo-container">
        <img src={classImage} alt="Photo de classe" className="class-photo" />
      </div>
      <div className="names-container">
        {positions.map(pos => (
          <Draggable
            key={`${pos.id}-${resetKey}`}
            position={pos.x !== undefined && pos.y !== undefined ? { x: pos.x, y: pos.y } : null} // Appliquer la position si elle existe
            onDrag={(e, data) => handleDrag(e, data, pos.id)} // Mettre à jour la position pendant le drag
          >
            <div className="name-label">{pos.name}</div>
          </Draggable>
        ))}
      </div>
      <br />
      <button onClick={resetPositions} className="reset-button">Reset</button>
    </div>
  );
}

export default App;
